
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { DetailChart } from './detailChart'
  import CarDetail from './Detail/CarDetail.vue'

@Component({
  components: {
    CarDetail,
  },
})
  export default class DetailCreditsByCar extends DetailChart {
  @Prop() credits: Record<string, any>
  @Prop() creditsTarget: Record<string, any>

  amount = 0
  target = 10
  cars = []

  // Methods
  getData () {
    if (
      !this.credits?.loans_evaluation_view_aggregate ||
      !this.creditsTarget?.settings_process) return
    const salesNumber = this.credits.loans_evaluation_view_aggregate?.aggregate?.count
    if (salesNumber !== undefined) {
      this.amount = salesNumber
    }
    const cars = this.credits?.loans_evaluation_view_aggregate?.nodes
    if (cars) {
      this.cars = [...cars].sort((prev, next) => {
        return -dayjs(prev.signed_date).diff(dayjs(next.signed_date))
      })
    }
    const creditsTarget = this.creditsTarget?.settings_process?.[0]?.config?.projectedUtility?.quantity
    if (creditsTarget) {
      this.target = creditsTarget
    }
  }

  @Watch('credits', { immediate: true, deep: true })
  @Watch('creditsTarget', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
