
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { channelColors } from '@/utils/channelColors'

@Component({})
  export default class PurchasesByChannelPie extends BaseChart {
  @Prop() purchases: Record<string, any>
  channelColors = channelColors
  channels = []

  // Methods
  getCountByChannel (channel) {
    if (!this.purchases?.sales_stock_view_aggregate?.nodes) return 0
    const filteredByChannel = this.purchases.sales_stock_view_aggregate.nodes.filter(node => {
      return node.channel?.name === channel
    })
    return filteredByChannel.length
  }

  getStock () {
    if (!this.purchases) return
    const purchases = this.purchases.sales_stock_view_aggregate?.nodes
    if (!purchases) return

    let channels = []
    purchases.forEach(sale => {
      const channel = sale.channel
      if (!channels.map(channel => channel.name).includes(channel.name)) {
        channels.push(channel)
      }
    })
    channels = channels.sort((prev, next) => {
      return this.getCountByChannel(next.name) - this.getCountByChannel(prev.name)
    })
    this.channels = channels

    this.series = channels.map(channel => {
      const filteredByChannel = purchases.filter(purchase => purchase.channel?.name === channel.name)
      return filteredByChannel.length
    })

    const colors = channels.map(channel => {
      return channelColors[channel.name]
    })

    this.chartOptions = {
      labels: channels.map(channel => channel.description),
      colors,
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      // dataLabels: {
      //   formatter: val => Math.round((val * this.salesNumber) / 100),
      // },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 1,
          },
        },
      },
    }
  }

  // Getters
  get salesNumber () {
    if (!this.purchases?.sales_stock_view_aggregate?.nodes) return
    return this.purchases.sales_stock_view_aggregate.nodes.length
  }

  // Watchers
  @Watch('purchases', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getStock()
    this.display = true
  }
  }
