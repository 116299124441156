
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { channelColors } from '@/utils/channelColors'

@Component({})
  export default class SalesByChannelPie extends BaseChart {
  @Prop() sales: Record<string, any>
  channelColors = channelColors
  channels = []

  // Methods
  getCountByChannel (channel) {
    if (!this.sales?.sales_stock_aggregate?.nodes) return
    const filteredByChannel = this.sales.sales_stock_aggregate.nodes.filter(node => {
      return node.deals?.[0]?.channel?.name === channel
    })
    return filteredByChannel.length
  }

  getStock () {
    if (!this.sales) return
    const sales = this.sales.sales_stock_aggregate?.nodes
    if (!sales) return

    let channels = []
    sales.forEach(sale => {
      const channel = sale.deals?.[0]?.channel
      if (!channel) {
        return
      }
      if (!channels.map(channel => channel.name).includes(channel.name)) {
        channels.push(channel)
      }
    })
    channels = channels.sort((prev, next) => {
      return this.getCountByChannel(next.name) - this.getCountByChannel(prev.name)
    })
    this.channels = channels

    this.series = channels.map(channel => {
      const filteredByChannel = sales.filter(sale => sale.deals?.[0]?.channel?.name === channel.name)
      return filteredByChannel.length
    })

    const colors = channels.map(channel => {
      return channelColors[channel.name]
    })

    this.chartOptions = {
      labels: channels.map(channel => channel.description),
      colors,
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      // dataLabels: {
      //   formatter: val => Math.round((val * this.salesNumber) / 100),
      // },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 1,
          },
        },
      },
    }
  }

  // Getters
  get salesNumber () {
    if (!this.sales?.sales_stock_aggregate?.nodes) return
    return this.sales.sales_stock_aggregate.nodes.length
  }

  // Watchers
  @Watch('sales', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getStock()
    this.display = true
  }
  }
