
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import dayjs from 'dayjs'

@Component({})
  export default class PurchasesVsSales extends BaseChart {
    // Methods
    mounted () {
      this.getData()
      this.display = true
    }

  @Prop() sales: Record<string, any>
  @Prop() purchases: Record<string, any>

    getData () {
      const sales = this.sales
      const purchases = this.purchases

      if (!sales && !purchases) return
      const { series, maxSum } = this.transformSalesData(sales?.sales_stock_aggregate, purchases?.sales_stock_view_aggregate)

      // this.chartOptions.xaxis.categories = categories
      this.series = series
      this.chartOptions = {
        chart: {
          stacked: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            formatter (val) {
              return dayjs(val).format('DD/MM')
            },
          },
        },
        yaxis: {
          min: 0,
          max: maxSum,
          stepSize: 1,
          forceNiceScale: true,
          decimalsInFloat: 0,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
      }
    }

  @Prop() dates

    transformSalesData (sales, purchases) {
      const categories = []
      const series = []

      if (!purchases && !sales) return { categories, series }
      this.generateDayRange(dayjs(this.dates.start), dayjs(this.dates.end))
        .forEach(date => {
          categories.push(date)
        })

      if (purchases.nodes) {
        series.push({ name: 'Compras', data: [], color: '#6185DB', type: 'bar' })
        categories.forEach(category => {
          const filteredData = purchases.nodes.filter(node => {
            if (node?.stock_created) {
              return dayjs(node.stock_created).format('DD/MM/YYYY') === dayjs(category).format('DD/MM/YYYY')
            }
          })
          series[series.length - 1].data.push({
            x: dayjs(category).format('YYYY/MM/DD/'),
            y: filteredData.length,
          })
        })
      }

      if (sales.nodes) {
        series.push({ name: 'Ventas', data: [], color: '#13CE66', type: 'bar' })
        categories.forEach(category => {
          const filteredData = sales.nodes.filter(node => {
            if (node?.sold_date) {
              return dayjs(node.sold_date).format('DD/MM/YYYY') === dayjs(category).format('DD/MM/YYYY')
            }
          })
          series[series.length - 1].data.push({
            x: dayjs(category).format('YYYY/MM/DD'),
            y: filteredData.length,
          })
        })
      }

      let maxSum = this.getMaxSum(series)

      maxSum = this.getMaxSum(series)

      for (let i = 0; i < categories.length; i++) {
        categories[i] = categories[i].format('YYYY/MM/DD')
      }

      if (maxSum % 2 !== 0) {
        maxSum++
      }

      return { categories, series, maxSum }
    }

    getMaxSum (series) {
      let maxSum = 0
      series.forEach(serie => {
        serie.data.forEach(value => {
          if (value.y > maxSum) maxSum = value.y
        })
      })

      return maxSum
    }

  // Getters

  // Watchers
  @Watch('sales', { immediate: true, deep: true })
  @Watch('leadsByChannel', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
