
  import { Debounce } from '@/utils/decorators'
  import { deepCopy, fixPrice } from '@/utils/general'
  import dayjs from 'dayjs'
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'

@Component({})
  export default class PurchasesReport extends Vue {
  @Prop() reports: Record<string, any>
  reportsAttribute = []
  executives = []
  fixPrice = fixPrice
  dayjs = dayjs

  getData () {
    if (!this.reports.negotiation) return
    const { unvalidated, negotiation, process } = deepCopy(this.reports)
    const reports = [
      {
        title: 'Inspecciones',
        data: unvalidated.sort((prev, next) => dayjs(prev.date).diff(dayjs(next.date), 'day')),
      },
      {
        title: 'Negociaciones',
        data: negotiation.sort((prev, next) => dayjs(prev.startDate).diff(dayjs(next.startDate), 'day')),
      },
      {
        title: 'Compras en proceso',
        data: process.sort((prev, next) => dayjs(prev.startDate).diff(dayjs(next.startDate), 'day')),
      },
    ]

    if (!reports) return

    let executives = []
    reports.forEach(reportGroup => {
      reportGroup.data.forEach(report => {
        if (!executives.includes(report.executiveName)) {
          executives.push(report.executiveName)
        }
      })
    })
    executives = executives.filter(executive => executive !== undefined)
    this.executives = executives
    this.reportsAttribute = reports
  }

  filterByExecutive (reportGroup, executive) {
    return reportGroup.filter(report => report.executiveName === executive)
  }

  getPriceByReport (report) {
    if (report.expectedPublicationAmount) {
      return report.expectedPublicationAmount
    }
    if (report.expectedPublicationPrice) {
      return report.expectedPublicationPrice
    }
    if (report.estimatedPublicationPrice) {
      return report.estimatedPublicationPrice
    }
    return 0
  }

  get totalInversions () {
    let count = 0
    const reports = this.reportsAttribute
      .map(report => report.data)
      .flat()
    reports.forEach(report => count += Number(report.agreedPrice))
    return count
  }

  get totalUtility () {
    let count = 0
    const reports = this.reportsAttribute
      .map(report => report.data)
      .flat()
    reports.forEach(report => count += Number(this.getPriceByReport(report)))
    count -= this.totalInversions
    return count
  }

  get purchasesCount () {
    if (!this.reportsAttribute) {
      return
    }
    let count = 0
    this.reportsAttribute
      .forEach(reportGroup => count += reportGroup.data.length)
    return count
  }

  @Watch('reports', { immediate: true, deep: true })
  @Debounce(500)
  onReportsChange () {
    this.getData()
  }
  }

