
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component({})
  export default class PeriodCredits extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() credits: Record<string, any>
  @Prop() target: Record<string, any>

  getData () {
    if (!this.credits || !this.target) return
    const credits = this.credits
    const targetCredits = this.target

    let creditsNumber = credits?.loans_evaluation_view_aggregate?.aggregate?.count
    if (!creditsNumber) creditsNumber = 0

    let monthlyTarget = targetCredits?.settings_process?.[0]?.config?.projectedUtility?.quantity
    if (!monthlyTarget) monthlyTarget = 5

    // const target = (monthlyTarget / 30) * (dayjs(endDate).diff(startDate, 'day') + 1)

    const percentage = (creditsNumber / monthlyTarget) * 100

    this.brief = `${creditsNumber} de ${monthlyTarget}`
    this.series = [Math.round(percentage)]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '40px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels: ['Créditos'],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  // Watchers
  @Watch('credits', { immediate: true, deep: true })
  @Watch('target', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
